// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty_container {
    height: 150px;
}

.circular_container {
    margin: 30px auto;
    display: flex;
    justify-content: center;
    border-radius: 30px;
    background-color: rgba(244, 248, 255, 1);
    width: 50px;
    height: 50px;
}

.image_container {
    margin: 10px 10px;
    width: 30px;
    height: 30px;
}

.h1_container {
    text-align: center;
}

.text_container {
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Success.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,wCAAwC;IACxC,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".empty_container {\n    height: 150px;\n}\n\n.circular_container {\n    margin: 30px auto;\n    display: flex;\n    justify-content: center;\n    border-radius: 30px;\n    background-color: rgba(244, 248, 255, 1);\n    width: 50px;\n    height: 50px;\n}\n\n.image_container {\n    margin: 10px 10px;\n    width: 30px;\n    height: 30px;\n}\n\n.h1_container {\n    text-align: center;\n}\n\n.text_container {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
