import React from 'react';
import {Route, Routes} from "react-router-dom";
import Payment from "./pages/Payment";
import {SuccessPage} from "./pages/Success";
import {FailPage} from "./pages/Fail";

function App () {
        return (
            <Routes>
                <Route path="/" element={<Payment/>}/>
                <Route path="/success" element={<SuccessPage/>}/>
                <Route path="/fail" element={<FailPage/>}/>
            </Routes>
        )
};

export default App;