// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment_btn {
    margin: 0% 5%;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 17px;
    width: 90%;
    height: 50px;
    background-color: ghostwhite;
    color: black;
    border: none;
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Payment.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uCAAuC;IACvC,eAAe;IACf,UAAU;IACV,YAAY;IACZ,4BAA4B;IAC5B,YAAY;IACZ,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".payment_btn {\n    margin: 0% 5%;\n    font-family: 'Noto Sans KR', sans-serif;\n    font-size: 17px;\n    width: 90%;\n    height: 50px;\n    background-color: ghostwhite;\n    color: black;\n    border: none;\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
