import React, {useEffect, useRef} from 'react';
import {loadPaymentWidget, PaymentWidgetInstance} from "@tosspayments/payment-widget-sdk";
import {encode as base64_encode} from 'base-64';
import "./Payment.css";
import {useSearchParams} from "react-router-dom";

const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY ?? ""
// const clientSecret = process.env.REACT_APP_TOSS_CLIENT_SECRET ?? ""

function Payment() {
    const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null)
    const [searchParams] = useSearchParams()
    const price = parseInt(searchParams.get("price") ?? "0")
    const orderName = searchParams.get("order-name") ?? "주문 오류"
    const customerName = searchParams.get("customer-name") ?? "주문 오류"
    const customerEmail = searchParams.get("name") ?? "***@projectwith.io"

    useEffect(() => {
        (async () => {
            const paymentWidget = await loadPaymentWidget(clientKey, createCustomerKey(orderName, customerName))
            paymentWidget.renderPaymentMethods("#payment-widget",
                price
            )
            paymentWidget.renderAgreement('#agreement')
            paymentWidgetRef.current = paymentWidget
        })()
    }, [])

    function checkOutBtn() {
        return <button
            className={"payment_btn"}
            onClick={async () => {
                const paymentWidget = paymentWidgetRef.current
                try {
                    await paymentWidget?.requestPayment({
                        orderId: createOrderId(orderName, customerName),
                        orderName: orderName,
                        customerName: customerName,
                        customerEmail: customerEmail,
                        successUrl: `${window.location.origin}/success`,
                        failUrl: `${window.location.origin}/fail`,
                    })
                } catch (err) {
                    console.log(err)
                }
            }}
        >
            결제하기
        </button>;
    }

    return (
        <div>
            <div id="payment-widget"/>
            <div id="agreement"/>
            {checkOutBtn()}
        </div>
    );
}

function createOrderId(orderName: string, customerName: string) {
    let orderId = orderName + ":" + customerName + ":" + Date.now()
    let encodedId = base64_encode(orderId)
    return encodedId
}

function createCustomerKey(orderName: string, customerName: string) {
    let customerKey = orderName + "@" + customerName + "@" + Math.floor(Date.now()/1000)
    return customerKey
}

export default Payment;