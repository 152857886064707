import React, {useEffect} from 'react';
import {useSearchParams} from "react-router-dom";
import successImage from 'src/images/success.png';
import './Success.css'
import {postOrder} from "../api/Api";

export function SuccessPage() {

    const [searchParams] = useSearchParams();
    const paymentKey = searchParams.get("paymentKey") ?? "paymentKey"
    const orderId = searchParams.get("orderId") ?? "orderId"
    const amount = searchParams.get("amount") ?? "0"
    const paymentType = searchParams.get("paymentType") ?? "paymentType"

    useEffect(() => {
        (() => {
            postOrder(
                paymentKey,
                orderId,
                parseInt(amount),
                paymentType
            )
        })()
    }, [])

    return (
        <div>
            <div className={"empty_container"}/>
            <div className={"circular_container"}>
                <img
                    src={successImage}
                    className={"image_container"}
                />
            </div>
            <h1 className={"h1_container"}>결제 성공</h1>
            <div className={"text_container"}>{`결제 금액: ${Number(
                searchParams.get("amount")
            ).toLocaleString()}원`}</div>
        </div>
    );
}