import React from 'react';
import { useSearchParams } from "react-router-dom";
import warningImage from "../images/warning.png";

export function FailPage() {
    const [searchParams] = useSearchParams();

    return (
        <div>
            <div className={"empty_container"}/>
            <div className={"circular_container"}>
                <img
                    src={warningImage}
                    className={"image_container"}
                />
            </div>
            <h1 className={"h1_container"}>결제 실패</h1>
            <div className={"text_container"}>{`사유: ${searchParams.get("message")}`}</div>
        </div>
    );
}