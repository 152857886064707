import axios from "axios";

const url = process.env.REACT_APP_SERVER_URL ?? ""
export function postOrder (paymentKey: String, orderId: String, amount: number, paymentType: String) {
    try {
        axios.post(
            url + "/payment",
            {
                paymentKey: paymentKey,
                orderId: orderId,
                amount: amount,
                paymentType: paymentType,
            }
        )
    } catch (e) {
        console.error('Error:', e);
    }
}